@import 'variables';

@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@mixin rotate($deg) {
    @include transform(rotate(#{$deg}deg));
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content
    }
    @-moz-keyframes #{$animation-name} {
        @content
    }
    @-ms-keyframes #{$animation-name} {
        @content
    }
    @-o-keyframes #{$animation-name} {
        @content
    }
    @keyframes #{$animation-name} {
        @content
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin filter-blur($arg) {
    -webkit-filter: blur($arg);
    -moz-filter: blur($arg);
    -ms-filter: blur($arg);
    -o-filter: blur($arg);
    filter: blur($arg);
}

@mixin position-sticky() {
    -webkit-position: sticky;
    -moz-position: sticky;
    -ms-position: sticky;
    -o-position: sticky;
    position: sticky;
    position: -webkit-sticky;
}

@mixin linear-gradient($direction, $fromColor, $toColor) {
    background-color: $toColor;
    background-image: -webkit-gradient(linear, $direction, from($fromColor), to($toColor));
    background-image: -webkit-linear-gradient($direction, $fromColor, $toColor);
    background-image: -moz-linear-gradient($direction, $fromColor, $toColor);
    background-image: -ms-linear-gradient($direction, $fromColor, $toColor);
    background-image: -o-linear-gradient($direction, $fromColor, $toColor);
    background-image: linear-gradient($direction, $fromColor, $toColor);
}

@mixin gradient($direction, $args...) {
    background-image: -webkit-linear-gradient($direction, $args);
    background-image: -moz-linear-gradient($direction, $args);
    background-image: -ms-linear-gradient($direction, $args);
    background-image: -o-linear-gradient($direction, $args);
    background-image: linear-gradient($direction, $args);
}

@mixin vertical-flex($gap: 12px) {
    display: flex;
    justify-content: space-between;

    flex-direction: column;
    & > * {
        margin: $gap 0;

        &:first-child {
            margin: 0 0 $gap;
        }

        &:last-child {
            margin: $gap 0 0;
        }
    }
}

@mixin loader-animation {
    background-image: linear-gradient(
            to right,
            rgba(#F6F6F6, 0.05) 8%, rgba(#F0F0F0, 0.1) 18%, rgba(#F6F6F6, 0.05) 53%);
    background-size: 800px 20px;
    animation: loader-animation 700ms infinite;
}

@mixin buttons() {

    .mat-button-focus-overlay {
        background: none;
    }

    .mat-button-wrapper {
        line-height: 40px;
    }

    .mat-stroked-button:not(.mat-button-disabled):not(.selected) {
        border-color: rgba(255, 255, 255, 0.3);
    }

    .mat-button,
    .mat-flat-button,
    .mat-raised-button,
    .mat-fab,
    .mat-stroked-button,
    .mat-mini-fab, .mat-mini-fab.mat-accent {
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #fff;
        transition: filter .3s, ease;

        &:not(.mat-button-disabled) {
            &:hover {
                filter: brightness(1.04);
            }

            &:active {
                filter: brightness(1.12);
            }
        }

        mat-icon {
            margin-top: -3px;
            line-height: 100%;
        }
    }

    .mat-flat-button,
    .mat-raised-button,
    .mat-fab,
    .mat-stroked-button,
    .mat-mini-fab, .mat-mini-fab.mat-accent {
        &.mat-primary {
            &.mat-button-disabled {
                background-color: $dark-gray;

                &.mat-button-disabled {
                    background-color: $dark-gray;
                }
            }
        }
    }

    .mat-fab, .mat-mini-fab {
        background-color: #424243;
    }

    .mat-flat-button,
    .mat-raised-button {
        border-radius: 8px !important;
        background-color: rgba(255, 255, 255, .12);

        &.small {
            line-height: 24px;
            padding: 0 8px;
            border-radius: 4px !important;

            .mat-button-wrapper {
                line-height: 24px;
            }
        }
    }

    .mat-flat-button {
        .mat-icon {
            height: 100%;
            margin-right: 8px;
        }
    }

    .mat-fab {
        width: 48px !important;
        height: 48px !important;

        .mat-button-wrapper {
            padding: 12px 0 !important;
        }

        mat-icon {
            height: 26px;
        }
    }

    .actions {
        button:not(:last-child) {
            margin-right: 16px;
        }
    }
}

@mixin medium-emphasis {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: rgba(255, 255, 255, 0.6);
}

@mixin card-text {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: rgba(255, 255, 255, 0.6);
}

@mixin border-glow() {
    --width: .125rem;
    --border-radius: 1rem;
    --inset: calc(-1 * var(--width));

    position: relative;
    border-radius: var(--border-radius);
    &::before, &::after {
        content: "";
        position: absolute;
        z-index: -1;
        top: var(--inset);
        right: var(--inset);
        bottom: var(--inset);
        left: var(--inset);
        border-radius: var(--border-radius);
        background: conic-gradient(from 33deg,
            #e9bf6e 0deg, #fe894f 39.6deg, #f44851 70.2deg, #e4326d 93.6deg, #c826aa 115.2deg, #7026a5 163.8deg, #5521c6 180deg,
            #5521c6 199.8deg, #7026a5 244.8deg, #c826aa 266.4deg, #e4326d 289.8deg, #f44851 320.4deg, #e9bf6e 360deg
        );
        background-size: 200% 200%;
        width: calc(100% + 2 * var(--width));
        height: calc(100% + 2 * var(--width));
    }

    &::before {
        animation: steam 7s linear infinite alternate;
    }
    &::after {
        animation: steam 13s linear infinite;
        filter: blur(10px);
    }
    @keyframes steam {
        0% {
            background-position: 10% 50%;
        }
        25% {
            background-position: 50% 90%;
        }
        50% {
            background-position: 90% 50%;
        }
        75% {
            background-position: 50% 10%;
        }
        100% {
            background-position: 10% 50%;
        }
    }
}

// TODO: Remove when https://caniuse.com/css-backdrop-filter in firefox
@mixin backdrop-workaround {
    background: rgba(0, 0, 0, 0) !important;
    transition: visibility 0.3s, background 0.3s !important;

    &.mat-drawer-shown {
        background: rgba(0, 0, 0, 0.8) !important;
    }
}

@mixin formFieldOutline() {

    $border-radius: .5rem;

    &.mat-form-field-disabled {
        .mat-form-field-outline-start, .mat-form-field-outline-gap, .mat-form-field-outline-end {
            background-color: rgba(255, 255, 255, .12);
            border: 0;
        }
    }

    .mat-form-field-outline-start, .mat-form-field-outline-end {
        min-width: $border-radius !important;
    }

    .mat-form-field-outline-start {
        border-radius: $border-radius 0 0 $border-radius !important;

        [dir='rtl'] & {
            border-radius: 0 $border-radius $border-radius 0 !important;
        }
    }

    .mat-form-field-outline-end {
        border-radius: 0 $border-radius $border-radius 0 !important;

        [dir='rtl'] & {
            border-radius: $border-radius 0 0 $border-radius !important;
        }
    }

    &.mat-focused .mat-form-field-label,
    &.mat-focused .mat-form-field-outline-thick {
        color: #fff;
    }

    &.mat-form-field:not(.mat-form-field-invalid) .mat-form-field-flex:hover {
        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
            border-width: 1px !important;
            color: rgba(255, 255, 255, 0.6);
            transition: color 100ms !important;
        }
    }

    &.mat-focused:not(.mat-form-field-invalid) .mat-form-field-flex:hover {
        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
            border-width: 2px !important;
            transition: color 100ms !important;
            color: #fff;
        }
    }

    .mat-select-arrow-wrapper {
        transform: none !important;

        .mat-select-arrow {
            color: #fff !important;
            transition: transform .2s linear;
        }
    }
}
