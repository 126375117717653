@font-face {
    font-family: GoogleSans;
    src: url(~projects/shared/assets/fonts/GoogleSans-Regular.ttf);
    font-weight: normal;
}

@font-face {
    font-family: GoogleSans;
    src: url(~projects/shared/assets/fonts/GoogleSans-Bold.ttf);
    font-weight: bold;
}

@font-face {
    font-family: GoogleSans;
    src: url(~projects/shared/assets/fonts/GoogleSans-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: Roboto;
    src: url(~projects/shared/assets/fonts/Roboto-Regular.ttf);
    font-weight: normal;
}

@font-face {
    font-family: Roboto;
    src: url(~projects/shared/assets/fonts/Roboto-Medium.ttf);
    font-weight: bold;
}

@font-face {
    font-family: "Noto Sans";
    src: url(~projects/shared/assets/fonts/NotoColorEmoji.ttf);
    font-weight: normal;
}
