$xs-mobile-min-width: 320px;
$mobile-width: 450px;
$xs-mobile-max-width: 575.98px;
$sm-mobile-min-width: 576px;
$sm-mobile-max-width: 767.98px;
$md-tablet-min-width: 768px;
$md-tablet-max-width: 991.98px;
$lg-desktop-min-width: 1212px;
$lg-desktop-max-width: 1334px;
$xl-desktop-min-width: 1760px;
$donateion-page-max-width: 1027px;
$finance-mid-width: 1625px;
$finance-min-width: 1535px;

$dark: #131315;
$error: #eb4e45;
$white: #ffffff;
$sky-blue: #66bbff;

$background: $dark;
$high-emphasis: #007aff;
$medium-emphasis: rgba(255, 255, 255, 0.6);

$blur-text: rgba(255, 255, 255, 0.3);
$focus-text: rgba(255, 255, 255, 0.87);
$dark-gray: rgba(255, 255, 255, 0.12);
$dark-gray: #424243;
$light-gray: rgba(255, 255, 255, 0.6);
$border-radius: 1rem;
$loader-placeholder: rgba(255, 255, 255, 0.08);
$orange: #feb202;

$widget-color-alert: #ffd609;
$widget-color-goal: #30d158;
$widget-color-top: #ff375f;
$widget-color-total: #c44de6;
$widget-color-media: #ff9f0a;
$widget-color-events: #64d2ff;

$widget-gradient: -webkit-linear-gradient(45deg, #efa875, #cba7cb 50%, #64b4f3);

$social-color-twitch: #835dca;
$social-color-twitch-2: #6542a6;
$social-color-twitch-text: #9b7dd4;
$social-color-youtube: #ef716a;
$social-color-youtube-2: #e62217;
$social-color-paypal: #84c8ff;
$social-color-paypal-2: #0081BF;
$social-color-facebook: #3a5997;
$social-color-trovo: #30be7a;
$social-color-vk: #4a76a8;

$social-color-twitch-dim: #3c2a5d;
$social-color-youtube-dim: #7c1a16;
$social-color-paypal-dim: #094167;

$card-background: rgba(255, 255, 255, 0.05);
$donate-card-background: #1f1f20;

$spacing-xxl-4: 40px;
$spacing-xxl-2: 32px;
$spacing-xl: 24px;
$spacing-m: 16px;
