@import '~bootstrap/scss/bootstrap-reboot';
@import '~projects/shared/src/sass/variables.scss';
@import '~projects/shared/src/sass/mixins.scss';
@import 'slider.sass';

html, body {
    width: 100vw;
    height: 100vh;
    position: fixed;
}

body {
    overflow: hidden;
}

body, .mat-drawer-container {
    background-color: $dark;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: $light-gray;
}

.cursor-pointer {
    cursor: pointer;
}

h1 {
    font-family: Montserrat, sans-serif;
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: -1px;
    margin: 0;
}

h2 {
    font-family: Montserrat, sans-serif;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
}

h4 {
    font-family: GoogleSans, sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}

h5 {
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

h6 {
    font-family: GoogleSans, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    margin: 0;
}

@include buttons();

.white {
    color: $white;
}

.blur-text {
    color: $blur-text;
}

.focus-text {
    color: $focus-text;
}

.light-gray {
    color: $light-gray;
}


.medium {
    font-family: GoogleSans, sans-serif;
    font-size: 16px;
}

.small {
    font-family: Roboto, "Noto Sans", sans-serif;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.12px;
}

textarea {
    resize: none !important;
}

textarea,
input {
    padding: 0 !important;

    &:focus-visible {
        outline: none;
    }
}

@-webkit-keyframes autofill {
    to {
        color: white;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.link {
    color: $sky-blue;
    cursor: pointer;

    &:hover {
        color: $sky-blue;
    }
}

@include keyframes(UnicornBreath) {
    0% {
        background-position: 0 0;
        @include filter-blur(80px);
    }
    50% {
        background-position: 100% 100%;
        @include filter-blur(80px);
    }
    100% {
        background-position: 0 0;
        @include filter-blur(80px);
    }
}

.noselect {
    user-select: none;
}

.md-drppicker {
    background-color: #36373a !important;
    border: 1px solid #383839 !important;
    box-shadow: none !important;
    border-radius: 4px;

    .calendar-table {
        border: 0 !important;
        background-color: #36373a !important;
    }

    td.start-date.end-date {
        border-radius: 0 !important;
        background-color: $sky-blue !important;
    }

    .available {
        background-color: inherit !important;

        &:hover {
            background-color: inherit !important;
        }
    }

    .select-item {
        color: rgba(255, 255, 255, 0.8) !important;

        option {
            color: black !important;
        }
    }

    th {
        min-width: 0 !important;
    }

    button {
        &.btn {
            background-color: #304ffe !important;
            border-radius: 8px !important;
            padding: 0 16px !important;
            text-transform: capitalize !important;
        }
    }

    .prev,
    .next {
        background-color: $sky-blue !important;
        border-radius: 8px !important;
        width: 28px !important;

        &:hover {
            background-color: $sky-blue !important;
            width: 28px !important;
        }
    }
}

.color-picker {
    background-color: #36373a !important;
    border: 1px solid #383839;
    border-radius: 4px;
    margin: auto;

    .cursor {
        border-color: $sky-blue !important;
    }

    .saturation-lightness {
        border-top-left-radius: 4px !important;
        border-top-right-radius: 4px !important;
    }

    .hue-alpha {
        background-color: #36373a;
    }

    .selected-color {
        border-radius: 4px !important;
        border: 0 !important;
    }

    .hue {
        margin-bottom: 7px !important;
    }

    .box {
        padding-right: 8px !important;
    }

    .hex-text {
        max-height: 40px;
        overflow: hidden;
    }

    input {
        caret-color: $sky-blue !important;
        font-family: GoogleSans, sans-serif !important;
        font-size: 14px !important;
        color: white !important;
        width: 100% !important;
        border: 0 !important;
        line-height: 1 !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
        background-color: rgba(0, 0, 0, 0);
        padding-bottom: 8px !important;
    }
}

.glow {
    .mat-ripple {
        box-shadow: 0 0 20px 0 #304ffe;
    }
}

.mat-card-subtitle,
.mat-card-content {
    font-size: 16px;
}

.mat-hint {
    color: rgba(255, 255, 255, 0.6);
}

.mat-form-field-appearance-legacy {
    font-size: 16px;
    letter-spacing: 0.16px;

    &.mat-focused .mat-form-field-ripple {
        background-color: #fff;
    }

    .mat-input-element {
        font-size: 16px;
        color: $focus-text !important;
    }

    input,
    textarea {
        min-height: 25px;
        caret-color: #fff;
        letter-spacing: 0.16px;
    }

    .mat-hint,
    .mat-form-field-underline {
        color: rgba(255, 255, 255, 0.3) !important;
    }

    .mat-form-field-underline {
        background-color: rgba(255, 255, 255, 0.3) !important;
    }

    .mat-form-field-suffix {
        font-size: 16px;
    }

    .mat-icon,
    .mat-hint,
    .mat-form-field-prefix,
    .mat-form-field-suffix {
        transition: color .2s ease;
    }

    .mat-form-field-subscript-wrapper {
        margin-top: 0.15em;
        top: calc(100% - 1.6666666667em);

        .mat-error {
            font-family: Roboto, sans-serif;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
        }
    }
}

.mat-form-field {
    letter-spacing: 0.15px;

    input {
        letter-spacing: 0.15px;
    }
}

.mat-form-field-appearance-outline {
    font-family: Roboto, sans-serif;
    font-size: 16px;

    .mat-form-field-infix {
        padding: 0.1em 0 0.682em;
    }

    .mat-form-field-prefix {
        top: 0 !important;
    }

    .mat-form-field-suffix {
        .mat-icon {
            text-rendering: optimizeLegibility;
            width: 20px;
            font-size: 19px;

            &[svgicon="logo-you-money"] {
                width: 28px;
                margin-right: -4px;
            }
        }
    }

    .mat-form-field-label-wrapper {
        letter-spacing: 0.15px;
        top: -1.5em;
    }

    &.mat-form-field-should-float {
        .mat-form-field-label-wrapper {
            top: -1em;
        }
    }

    @include formFieldOutline();

    .mat-form-field-flex {
        padding: 0 .8em 0 1em !important
    }

    .mat-form-field-subscript-wrapper {
        padding: 0 1.4em !important
    }

}

.mat-select[aria-expanded="true"] {
    .mat-select-arrow {
        transform: rotateZ(180deg) !important;
    }
}

form[dense] {
    .mat-form-field-appearance-outline .mat-form-field-infix {
        padding: .641em 0 .641em 0 !important;
        margin-top: -.5em;
    }

    .mat-form-field-label {
        color: rgba(255, 255, 255, 0.6);
    }

    .mat-form-field-wrapper {
        padding-bottom: 0;
    }

    .mat-form-field-label-wrapper {
        top: -.95em;
        letter-spacing: 0.15px;
    }

    .mat-form-field-should-float .mat-form-field-label-wrapper {
        top: -.35em;
        letter-spacing: 0.4px;
    }

    .mat-form-field-appearance-outline .mat-form-field-prefix,
    .mat-form-field-appearance-outline .mat-form-field-suffix {
        font-size: 16px;
        top: -1px;
        color: rgba(255, 255, 255, 0.6);
    }

    .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
        top: auto;
        margin-top: 3px;
    }

    textarea {
        resize: none;
        line-height: 24px;
    }
}

.mat-expansion-panel {
    background-color: transparent;
    box-shadow: none !important;
}

.mat-menu-panel {
    background-color: #2f2f31;
    border-radius: 8px !important;
}

.mat-menu-item {
    height: 40px !important;
    line-height: 40px !important;
    letter-spacing: 0.25px;
}

mat-expansion-panel.sortable-drag .mat-expansion-panel-content {
    display: none;
}

.mat-tooltip {
    font-family: Roboto, sans-serif;
    background-color: rgba(95, 95, 95, 0.88);
    color: #fff !important;
    font-size: 12px;
    letter-spacing: 0.4px;
    padding: 4px 8px;
    border-radius: 4px;
}

.mat-slider-thumb {
    background-image: url(/assets/icons/single-slider.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 24px !important;
    height: 16px !important;
    border-radius: 16px !important;
    transform: none !important;
    bottom: -9px !important;
}

.mat-slider[thumbLabel] {
    .mat-slider-thumb-label {
        transform: translate(-1px, 44px) !important;
        border-radius: 0 !important;
        background: transparent !important;
    }

    .mat-slider-thumb-label-text {
        opacity: 1 !important;
        color: $white !important;
        transform: none !important;
    }
}

.mat-slider-wrapper {
    height: 4px;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
    height: 4px;
    border-radius: 2px;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
    height: 100%;
}

.mat-slider-track-background,
.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
    background-color: rgba(0, 122, 255, 0.3) !important;
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
    background-color: $high-emphasis !important;
}

.mat-slider-horizontal .mat-slider-ticks-container,
.mat-slider-horizontal .mat-slider-wrapper::after {
    height: 4px !important;
}

.mat-slider-horizontal .mat-slider-ticks {
    height: 100% !important;
}

.mat-chip-list-wrapper {
    overflow: hidden;
}

.mat-standard-chip {
    &:not(.mat-chip-selected) {
        background-color: rgba(255, 255, 255, 0.12) !important;
    }

    font-family: Roboto, sans-serif;
    font-size: 14px;
    letter-spacing: 0.25px;

    > span {
        margin-left: 10px;
    }

    .mat-icon-button {
        width: 21px;
        margin-left: 4px;
        color: rgba(255, 255, 255, 0.6);

        &:hover {
            color: rgba(255, 255, 255, 1);
        }

        .mat-icon {
            font-size: 18px;
        }
    }

    .mat-icon {
        flex-shrink: 0;
    }
}

a:hover {
    text-decoration: none;
    color: inherit;
}

.mat-card {
    font-family: Montserrat, sans-serif;
    background-color: $card-background;

    .mat-card-header-text {
        margin: 0;
        line-height: 1.2;
    }

    .mat-card-title {
        font-family: Montserrat, sans-serif;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        margin-bottom: 6px;
    }

    .mat-card-content {
        font-family: Roboto, sans-serif;
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 1.3;
    }

}

.mat-dialog-title {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px !important;
    line-height: 1.5;
}

.mat-dialog-container {
    padding: 21px 24px 24px 24px !important;
    border-radius: 8px !important;
}

.mat-select-panel, .mat-autocomplete-panel {
    background-color: #2e2e30;
}

.mat-select-panel {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14) !important;
    border-radius: 8px !important;
    position: absolute;
    top: 30px;
}

::-webkit-scrollbar {
    width: 2px;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.38);
}

::-webkit-scrollbar-button {
    height: 8px;
}

.mat-option-pseudo-checkbox {
    margin-right: 19px !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
.mat-pseudo-checkbox::after {
    color: #fff;
}

a {
    color: #fff;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.settings-form {

    border-radius: 8px !important;

    .mat-card-header .mat-card-title {
        margin-bottom: 6px;
    }

    .mat-card-content {
        p {
            color: rgba(255, 255, 255, 0.6);
            margin-bottom: 20px;
        }
    }

    mat-card-actions {
        text-align: end;
        padding: 12px 0;
        margin: 0;

        &:last-child {
            margin-bottom: -1px !important;
        }
    }

    fieldset {
        display: flex;
        margin-bottom: 18px;
    }

    mat-label {
        display: inline-block;
        width: 184px;
        vertical-align: top;
        font-size: 14px;
        margin-top: 15px
    }

    mat-form-field {
        flex-grow: 1;
    }

}

.mat-slide-toggle {
    .mat-slide-toggle-bar {
        .mat-button-toggle-focus-overlay {
            display: none !important;
        }

        &:hover .mat-slide-toggle-persistent-ripple {
            opacity: 0 !important;
        }

        height: 24px !important;
        width: 40px !important;
        border-radius: 12px !important;
    }

    &.mat-checked .mat-slide-toggle-bar {
        background-color: $high-emphasis;
    }

    .mat-slide-toggle-thumb-container {
        top: 2px !important;
        padding-left: 2px;
        color: #fff !important;

        .mat-slide-toggle-thumb {
            background-color: #fff;
        }
    }

    [dir=rtl] .mat-slide-toggle-label-before .mat-slide-toggle-bar, .mat-slide-toggle-bar {
        margin-right: 18px;
    }

}

.filter-backdrop {
    backdrop-filter: blur(8px);
}

mat-tab-header {
    background-color: #313235;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 2px 2px 0 rgb(0 0 0 / 14%);
    color: white;

    .mat-tab-label {
        min-width: 75px;
        height: 55px;
        padding: 0 12px;
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        font-weight: 600;
        transition: opacity 300ms ease;

        &.cdk-focused {
            &:not(.mat-tab-label-active) {
                color: currentColor;
            }
        }

        &.mat-tab-label-active {
            opacity: 1;
        }
    }
}

mat-ink-bar {
    background-image: linear-gradient(92deg, #efa875, #cba7cb 50%, #64b4f3);
}
