.ng2-nouislider
    margin: 0 !important

.noUi-handle
    border: 0 !important

.noUi-target
    background: rgb(63, 63, 65) !important
    border: none !important
    height: 4px !important
    border-radius: 2px !important
    box-shadow: inherit !important

.noUi-horizontal 
    .noUi-handle
        width: 24px !important
        height: 16px !important
        border-radius: 8px
        cursor: pointer
        &:before
            content: '' !important
            position: absolute
            top: 4px
            left: 3px
            @include transform(rotate(90deg))
            background: rgba(255,255,255,0)
            background-image: url('/assets/icons/arrow-drop-down.svg')
            background-size: 8px 8px
            height: 8px
            width: 8px 
        &:after
            content: '' !important
            position: absolute
            top: 4px
            left: 13px
            @include transform(rotate(-90deg))
            background: rgba(255,255,255,0)
            background-image: url('/assets/icons/arrow-drop-down.svg')
            background-size: 8px 8px
            height: 8px
            width: 8px

.noUi-handle-lower
    display: none

.non-equal
    .noUi-handle-upper
        width: 16px !important
        transform: translateX(-2px) !important
        &:before
            left: 3px
        &:after
            background-image: none !important

    .noUi-handle-lower
        display: block
        width: 16px !important
        transform: translateX(-1px) !important
        &:before
            @include transform(rotate(-90deg))
            left: 4px
        &:after
            background-image: none !important

.noUi-tooltip
    border: none !important
    color: rgba(255, 255, 255, 0.6) !important
    font-size: 12px !important
    font-family: Roboto !important
    background: none !important
    text-align: center !important
    width: 100px
    transform: translateY(43px) translateX(-49px) !important

.noUi-connect
    background: #66bbff !important

